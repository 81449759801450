import React from "react"

const Footer = props => {
  return (
    <footer className="text-center text-white my-1">
      &copy; {new Date().getFullYear()}
    </footer>
  )
}

export default Footer
