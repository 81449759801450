/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import Footer from "../components/footer"

const Layout = ({ children, showFooter = true }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Urja Acharya</title>
      </Helmet>
      <main className="flex flex-wrap w-screen md:max-w-screen-lg mx-auto">
        {children}
      </main>
      {showFooter ? (
        <div
          className="rounded"
          style={{
            height: "35px",
            backgroundColor: "#81415f",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Footer />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
